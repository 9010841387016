const routes = [
  {
    path: '/',
    redirect: '/landPage'
  },
  {
    name: 'landPage',
    path: '/landPage',
    component: () => import('@/view/landPage')
  },
  {
    name: 'signup',
    path: '/signup',
    component: () => import('@/view/signup')
  },
  {
    name: 'mobile',
    path: '/mobile',
    component: () => import('@/view/mobile')
  },
  {
    name: 'appDownload',
    path: '/appDownload',
    component: () => import('@/view/appDownload')
  },
  
];

export default routes
